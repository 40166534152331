import React, { useEffect, lazy, Suspense } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  HostelGetAll,
  AnnouncementGetAll,
  ServicesGetAll,
  OtherServicesGetAll
} from "./Redux/Feature/CommonSlice";
import { Box } from "@mui/system";
import ContactList from "./Pages/Admin/ContactUs/ContactList";
import ViewContact from "./Pages/Admin/ContactUs/ViewContact";

// import Dashboard from "./Pages/User/Dashboard";

// import Main from "./Pages/User/Main";
// import NewsReview from "./Pages/User/News/NewsReview";
// import NewsList from "./Pages/User/News/NewsList";
// import Application from "./Pages/User/Application";
// import Services from "./Pages/User/Services";
// import Intro from "./Pages/User/Intro";
// import Account from "./Pages/User/Account";
// import ContactUs from "./Pages/User/Account/ContactUs";
// import ChecklistItems from "./Pages/User/Account/CheckList/ChecklistItems";
// import CheckoutForm from "./Pages/User/Account/CheckoutForm";
// import BreakContact from "./Pages/User/Account/BreakContact.jsx";
// import HostelReview from "./Pages/User/HostelReview";
// import LandingPage from "./LandingPage";

// import AdminDashboard from "./Pages/Admin/Dashboard";
// import AnnouncementList from "./Pages/Admin/Announcement/AnnouncementList";
// import AnnouncementAdd from "./Pages/Admin/Announcement/AnnouncementAdd";
// import AdminNewsList from "./Pages/Admin/News/NewsList";
// import AdminNewsAdd from "./Pages/Admin/News/NewsAdd";
// import AdminHostelAdd from "./Pages/Admin/Hostel/HostelAdd";
// import Hostellist from "./Pages/Admin/Hostel/Hostellist";
// import AdminApplication from "./Pages/Admin/Application/ApplicationList";
// import AdminServices from "./Pages/Admin/Services";
// import ApplicationReview from "./Pages/Admin/Application/ApplicationReview";


// import EditAnnouncement from "./Pages/Admin/Announcement/EditAnnouncement.jsx";
// import NewsEdit from "./Pages/Admin/News/NewsEdit.jsx";
// import EditHostel from "./Pages/Admin/Hostel/EditHostel.jsx";
// import PageNotFound from "../src/LandingPage/PageNotFound"
// import Contract from "./Pages/User/Application/Contract";
// import AddServices from "./Pages/Admin/Services/AddServices";
// import AddOtherService from "./Pages/Admin/Services/AddOtherService";
// import EditOtherService from "./Pages/Admin/Services/EditOtherService";
// import EditServices from "./Pages/Admin/Services/EditServices";
// import CheckListMain from "./Pages/Admin/Checklist/CheckListMain";
// import AddCheckList from "./Pages/Admin/Checklist/AddCheckList";
// import EditCheckList from "./Pages/Admin/Checklist/EditCheckList";
// import ChargesMain from "./Pages/Admin/Charges/ChargesMain";
// import AddDeposits from "./Pages/Admin/Charges/AddDeposits";
// import EditDeposits from "./Pages/Admin/Charges/EditDeposits";
// import AddMainAmount from "./Pages/Admin/Charges/AddMainAmount";
// import EditMainAmount from "./Pages/Admin/Charges/EditMainAmout";
// import Queries from "./Pages/Admin/Query/Queries";
// import ViewQueries from "./Pages/Admin/Query/ViewQueries";



const Dashboard = lazy(() => import('./Pages/User/Dashboard'))
const NewsList = lazy(() => import('./Pages/User/News/NewsList'))
const Intro = lazy(() => import('./Pages/User/Intro'))
const Application = lazy(() => import('./Pages/User/Application'))
const Services = lazy(() => import('./Pages/User/Services'))
const Main = lazy(() => import('./Pages/User/Main'))
const ContactUsMain = lazy(() => import('./Components/ContactUsMain')) 

const Account = lazy(() => import('./Pages/User/Account')) 
const ContactUs = lazy(() => import('./Pages/User/Account/ContactUs')) 
const ChecklistItems = lazy(() => import('./Pages/User/Account/CheckList/ChecklistItems')) 
const CheckoutForm = lazy(() => import('./Pages/User/Account/CheckoutForm')) 
const BreakContact = lazy(() => import('./Pages/User/Account/BreakContact.jsx')) 
const HostelReview = lazy(() => import('./Pages/User/HostelReview')) 
const LandingPage = lazy(() => import('./LandingPage'))

const NewsReview = lazy(() => import('./Pages/User/News/NewsReview'))

const AdminDashboard = lazy(() => import('./Pages/Admin/Dashboard'))
const AnnouncementList = lazy(() => import('./Pages/Admin/Announcement/AnnouncementList'))
const AnnouncementAdd = lazy(() => import('./Pages/Admin/Announcement/AnnouncementAdd'))
const AdminNewsList = lazy(() => import('./Pages/Admin/News/NewsList'))
const AdminNewsAdd = lazy(() => import('./Pages/Admin/News/NewsAdd'))
const AdminHostelAdd = lazy(() => import('./Pages/Admin/Hostel/HostelAdd'))
const Hostellist = lazy(() => import('./Pages/Admin/Hostel/Hostellist'))
const AdminApplication = lazy(() => import('./Pages/Admin/Application/ApplicationList'))
const AdminServices = lazy(() => import('./Pages/Admin/Services'))
const ApplicationReview = lazy(() => import('./Pages/Admin/Application/ApplicationReview'))

const EditAnnouncement = lazy(() => import('./Pages/Admin/Announcement/EditAnnouncement.jsx'))
const NewsEdit = lazy(() => import('./Pages/Admin/News/NewsEdit.jsx'))
const EditHostel = lazy(() => import('./Pages/Admin/Hostel/EditHostel.jsx'))
const PageNotFound = lazy(() => import('../src/LandingPage/PageNotFound'))
const Contract = lazy(() => import('./Pages/User/Application/Contract'))
const AddServices = lazy(() => import('./Pages/Admin/Services/AddServices'))
const AddOtherService = lazy(() => import('./Pages/Admin/Services/AddOtherService'))
const EditOtherService = lazy(() => import('./Pages/Admin/Services/EditOtherService'))
const EditServices = lazy(() => import('./Pages/Admin/Services/EditServices'))
const CheckListMain = lazy(() => import('./Pages/Admin/Checklist/CheckListMain'))
const AddCheckList = lazy(() => import('./Pages/Admin/Checklist/AddCheckList'))
const EditCheckList = lazy(() => import('./Pages/Admin/Checklist/EditCheckList'))
const ChargesMain = lazy(() => import('./Pages/Admin/Charges/ChargesMain'))
const AddDeposits = lazy(() => import('./Pages/Admin/Charges/AddDeposits'))
const EditDeposits = lazy(() => import('./Pages/Admin/Charges/EditDeposits'))
const AddMainAmount = lazy(() => import('./Pages/Admin/Charges/AddMainAmount'))
const EditMainAmount = lazy(() => import('./Pages/Admin/Charges/EditMainAmout'))
const Queries = lazy(() => import('./Pages/Admin/Query/Queries'))
const ViewQueries = lazy(() => import('./Pages/Admin/Query/ViewQueries'))


function App() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.AdminData.admin)
  console.log(data, 'data from app.js')
 
  useEffect(() => {
    dispatch(HostelGetAll());
    dispatch(AnnouncementGetAll());
    dispatch(ServicesGetAll());
    dispatch(OtherServicesGetAll());
  }, []);


  return (
    <div className="App">
      <BrowserRouter>
      <Suspense fallback={<Box sx={{display : 'flex', justifyContent : 'center', alignItems : 'center', height : '100vh'}}><div className="loader"></div></Box>}>
        <Routes>
          {/* User/ */}
          <Route path="/login" element={<LandingPage />} />

          <Route path="/" element={<Dashboard />}>
            {data?.isAdmin === false && (<>
              <Route path="/account" element={<Account />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/Checklist" element={<ChecklistItems />} />
            <Route path="/CheckoutForm" element={<CheckoutForm />} />
            <Route path="/Breakcontact" element={<BreakContact />} />
            <Route path="/hostelApplication" element={<Contract />} />
            </>)}
           

            <Route index element={<Main />} />
            <Route path="/news" element={<NewsList />} />
            <Route path="/news/:id" element={<NewsReview />} />
            <Route path="/application" element={<Application />} />
            <Route path="/services" element={<Services />} />
            <Route path="/intro" element={<Intro />} />
            <Route path="/hostelreview/:id" element={<HostelReview />} />
            <Route path="/contact-us" element={<ContactUsMain />} />
            
          </Route>
          
          <Route path="/*" element={<PageNotFound />} />
          
          {data?.isAdmin === true && (<>
            <Route path="/Admin" element={<AdminDashboard />}>
            {/* <Route path="/Admin/AnnouncementList" element={<AnnouncementList />} /> */}
            <Route index element={<AnnouncementList />} />
            <Route
              path="/Admin/AnnouncementAdd"
              element={<AnnouncementAdd />}
            />
            <Route
              path="/Admin/edit-announcement/:id"
              element={<EditAnnouncement />}
            />
            <Route path="/Admin/NewsList" element={<AdminNewsList />} />
            <Route path="/Admin/NewsAdd" element={<AdminNewsAdd />} />
            <Route path="/Admin/edit-news/:id" element={<NewsEdit />} />
            <Route path="/Admin/HostelAdd" element={<AdminHostelAdd />} />
            <Route path="/Admin/edit-hostel/:id" element={<EditHostel />} />
            <Route path="/Admin/Hostellist" element={<Hostellist />} />
            <Route path="/Admin/Application" element={<AdminApplication />} />
            <Route path="/Admin/Services" element={<AdminServices />} />
            
            <Route path="/Admin/Checklist" element={<CheckListMain />} />
            <Route path="/Admin/AddChecklist" element={<AddCheckList />} />
            <Route path='/Admin/edit-checklist/:id' element={<EditCheckList />} />
            
            <Route path="/Admin/Charges" element={<ChargesMain />} />
            <Route path='/Admin/add-deposit' element={<AddDeposits />} />
            <Route path='/Admin/edit-deposit/:id' element={<EditDeposits />} />
            <Route path='/Admin/add-main-amount' element={<AddMainAmount />} />
            <Route path="/Admin/edit-main-amount/:id" element={<EditMainAmount />} />
            
            <Route path='/Admin/Queries' element={<Queries />} />
            <Route path="/Admin/view-query/:id" element={<ViewQueries />} />

            <Route path='/Admin/contact-us' element={<ContactList />} />
            <Route path="/Admin/view-contact/:id" element={<ViewContact />} />

            <Route path='/Admin/add-service' element={<AddServices />} />
            <Route path='/Admin/edit-service/:id' element={<EditServices />} />
            <Route path='/Admin/add-other-service' element={<AddOtherService />} />
            <Route path='/Admin/edit-other-service/:id' element={<EditOtherService />} />
            <Route
              path="/Admin/ApplicationView/:id"
              element={<ApplicationReview />}
            />
          </Route>
          </>)}
          
        </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
